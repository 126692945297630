import GATSBY_COMPILED_MDX from "C:/Users/ribbe/WebstormProjects/rebel_thorp_umbrella/client/app/src/content/pages/privacy-policy.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import {MDXProvider} from '@mdx-js/react';
import {startCase} from 'lodash';
import {HTML, POSITION} from '@core/constants';
import {useHtmlClassName} from '@core/hooks';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import Hero from '@components/Hero';
import Sidebar from '@components/Sidebar';
import Content from '@components/Content';
import Centerer from '@components/Centerer';
import Link from '@components/Link';
import Message from '@components/Message';
import TableOfContents from '@components/mdx/TableOfContents';
import ListIcon from '@assets/list.svg';
const components = {
  Link,
  Message
};
function PageTemplate({data, children}) {
  const {frontmatter, tableOfContents} = data.mdx;
  const pageTitle = startCase(frontmatter.title);
  const heroImage = getImage(data.heroImage);
  const hasTableOfContents = !!tableOfContents.items;
  const [isActive, setIsActive] = React.useState(false);
  useHtmlClassName(HTML.CLASSNAME.WITH_TOPBAR_OFFSET);
  const handleFloatingSidebarOpen = () => setIsActive(true);
  const handleFloatingSidebarClose = () => setIsActive(false);
  return React.createElement(Main, null, React.createElement(Hero, {
    title: pageTitle,
    description: () => frontmatter.editedAt ? React.createElement(React.Fragment, null, "Updated at ", React.createElement("mark", null, frontmatter.editedAt)) : React.createElement(React.Fragment, null, frontmatter.subTitle),
    image: heroImage
  }), React.createElement(Centerer, {
    tagName: "section",
    withVerticalPadding: true,
    sidebar: hasTableOfContents ? POSITION.LEFT : null
  }, hasTableOfContents && React.createElement(Sidebar, {
    sticky: true,
    floating: true,
    floatingLabel: "Table of contents",
    floatingIcon: React.createElement(ListIcon, {
      width: 24,
      height: 24
    }),
    isActive: isActive,
    onRequestOpen: handleFloatingSidebarOpen,
    onRequestClose: handleFloatingSidebarClose
  }, React.createElement(TableOfContents, {
    items: tableOfContents.items,
    onClick: handleFloatingSidebarClose
  })), React.createElement(Content, {
    className: "format"
  }, React.createElement(MDXProvider, {
    components: components
  }, children))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export function Head({data}) {
  const {frontmatter} = data.mdx;
  return React.createElement(BaseHead, {
    title: startCase(frontmatter.title)
  });
}
export const query = graphql`
    query ($slug: String!) {
        mdx(fields: { source: { eq: "pages" }, slug: { eq: $slug } }) {
            id
            frontmatter {
                title
                subTitle
                editedAt(formatString: "MMMM Do, YYYY")
            }
            tableOfContents
        }
        heroImage: file(
            name: { eq: $slug }, sourceInstanceName: { eq: "images" }
        ) {
            ...BackgroundImage
        }
    }
`;
